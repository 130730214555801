import React, { useEffect, useRef, useState } from "react";

import Header from "../../components/Header/Header";
import Sensor from "../../components/Sensor/Sensor";
import DataManager from "../../API/DataManager";
import { useDispatch } from "react-redux";
import { setDate, setIsLoading, setTime } from "../../store/store";

import "./AllSensors.css";

const App: React.FC = () => {
  const [devices, setDevices] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const isFetchingDataRef = useRef(false);

  const fetchAllDevices = async () => {
    isFetchingDataRef.current = true;
    dispatch(setIsLoading(true));

    try {
      const result = await DataManager.getSensors();

      let datetime = result.datetime;
      dispatch(setDate(datetime.split(" ")[0]));
      const timeWithoutSeconds = datetime
        .split(" ")[1]
        .split(":")
        .slice(0, 2)
        .join(":");
      dispatch(setTime(timeWithoutSeconds));

      let devices = result.devices;
      for (let i = devices.length - 1; i >= 0; i--) {
        if (!devices[i].hasOwnProperty("name")) {
          devices.splice(i, 1);
        }
      }
      setDevices(devices);
    } catch (err: any) {
      setError(err.message);
    } finally {
      dispatch(setIsLoading(false));
      isFetchingDataRef.current = false;
    }
  };

  useEffect(() => {
    if (!isFetchingDataRef.current) fetchAllDevices();
  }, []);

  if (error) return <div>Errore: {error}</div>;

  return (
    <div className="App">
      <Header />
      <div className="sensors-container">
        {devices.map((device, index) => (
          <Sensor key={device.name} device={device} />
        ))}
      </div>
    </div>
  );
};

export default App;
