import React, { useEffect, useRef, useState } from "react";
import Area, { AreaModel } from "../../components/Area/Area";
import HeatTripleSwitch from "../../components/HeatTripleSwitch/TripleSwitch/HeatTripleSwitch";
import LevelControl from "../../components/LevelControl/LevelControl";
import "./HeatDashboard.css";

interface AreaListProps {
  areaData: AreaModel[];
  areaDataChanged: (areas: AreaModel[]) => void;
}

const HeatDashboard: React.FC<AreaListProps> = ({
  areaData,
  areaDataChanged,
}) => {
  const [isLevelControlVisible, setIsLevelControlVisible] = useState(false);
  const [levelVal, setLevelVal] = useState<AreaModel | null>(null);

  const openLevelControl = (area: AreaModel) => {
    setLevelVal(area);
    setIsLevelControlVisible(true);
  };

  const closeLevelControl = (editedArea: AreaModel) => {
    const updatedAreaData = areaData.map((area) => {
      if (area.area === editedArea.area) return { ...area, ...editedArea };
      return area;
    });

    areaDataChanged(updatedAreaData);
    setIsLevelControlVisible(false);
    setLevelVal(null);
  };

  return (
    <div>
      <div className="areas-container">
        {areaData.map((area, index) => (
          <Area
            key={index}
            area={area}
            isHeatingOn={true}
            onTouch={openLevelControl}
          />
        ))}
      </div>
      <div style={heatSwitch} className="heat-switch-container">
        <HeatTripleSwitch />
      </div>
      {isLevelControlVisible && (
        <LevelControl area={levelVal} onClose={closeLevelControl} />
      )}
    </div>
  );
};

const heatSwitch: React.CSSProperties = {
  display: "flex",
  justifyContent: "center" /* Centra orizzontalmente */,
  marginTop: "20px" /* Spazio sopra lo switch, opzionale */,
};

export default HeatDashboard;
