import "./Area.css";

export interface AreaModel {
  status: number;
  area: string;
  humidity: number;
  lastupdated?: string;
  heating: string;
  isOn: string;
  battery: number;
  targetHeating?: number;
}

interface AreaElementProps {
  area: AreaModel;
  isHeatingOn: boolean;
  onTouch?: (area: AreaModel) => void;
}

const AreaElement: React.FC<AreaElementProps> = ({
  area,
  isHeatingOn,
  onTouch,
}) => {
  const flameLogo =
    '<svg fill="#ff0000" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000" width="20px" height="20px" viewBox="0 0 512 512"><g id="SVGRepo_bgCarrier" stroke-width="0"  ></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title>ionicons-v5-m</title><path d="M394.23,197.56a300.43,300.43,0,0,0-53.37-90C301.2,61.65,249.05,32,208,32a16,16,0,0,0-15.48,20c13.87,53-14.88,97.07-45.31,143.72C122,234.36,96,274.27,96,320c0,88.22,71.78,160,160,160s160-71.78,160-160C416,276.7,408.68,235.51,394.23,197.56ZM288.33,418.69C278,429.69,265.05,432,256,432s-22-2.31-32.33-13.31S208,390.24,208,368c0-25.14,8.82-44.28,17.34-62.78,4.95-10.74,10-21.67,13-33.37a8,8,0,0,1,12.49-4.51A126.48,126.48,0,0,1,275,292c18.17,24,29,52.42,29,76C304,390.24,298.58,407.77,288.33,418.69Z"></path></g></svg>';
  const humLogo =
    '<svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" width="12px" height="12px" viewBox="0 0 470.34 470.34"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M235.169,470.34c99.262,0,180.016-80.756,180.016-180.018c0-41.055-28.979-101.928-86.129-180.933 C287.262,51.617,244.866,4.585,244.442,4.117C242.072,1.496,238.705,0,235.17,0c-3.534,0-6.903,1.496-9.273,4.117 c-0.423,0.469-42.819,47.5-84.613,105.272c-57.152,79.004-86.13,139.878-86.13,180.933 C55.152,389.584,135.907,470.34,235.169,470.34z M161.41,124.223c29.423-40.688,59.156-75.953,73.761-92.8 c14.578,16.813,44.227,51.977,73.628,92.621c53.244,73.6,81.387,131.099,81.387,166.278c0,85.477-69.541,155.018-155.017,155.018 c-85.477,0-155.017-69.541-155.017-155.018C80.152,255.173,108.251,197.736,161.41,124.223z"></path> </g> </g></svg>';
  const refreshLogo =
    '<svg fill="#ffffff" height="11px" width="11px" viewBox="0 0 383.748 383.748" version="1.1" id="refresh-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30 C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593 L2.081,34.641v113.365h113.91L62.772,95.042z"></path> <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042 c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888 c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z"></path> </g> </g></svg>';
  const flameLogoMini =
    '<svg fill="none"  width="15px" height="15px" viewBox="0 0 50 60" xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M37.34,7.36a.12.12,0,0,1,.18.13c-.47,1.86-2.78,12.63,5.57,19.62,8.16,6.84,8.41,17.13,2.33,24-7.27,8.23-19.84,6.78-25.25,1.37C16.36,48.69,9.44,36.33,21.29,26a.1.1,0,0,1,.16,0c.29,1.23,2.3,9,7.66,10,.25,0,.37-.11.25-.34C27.78,32.6,20.66,17,37.34,7.36Z" stroke-linecap="round"></path></g></svg>';

  const parseDate = (dateString: string): string => {
    if (!dateString.endsWith("Z")) {
      dateString += "Z";
    }
    const date = new Date(Date.parse(dateString));
    const now = new Date();
    const diffTime = Math.abs(date.getTime() - now.getTime());
    const minutes = Math.floor(diffTime / 1000 / 60);

    if (minutes < 1) return "now";
    if (minutes < 60) return `${minutes} min fa`;
    const hours = Math.floor(minutes / 60);
    if (hours < 3) return `${hours} ore fa`;
    if (hours < 24) return date.toLocaleTimeString().slice(0, 5);
    return date.toLocaleDateString();
  };

  const onContainerTouch = () => {
    if (onTouch) {
      onTouch(area);
    }
  };
  return (
    <div className="area-container" onClick={onContainerTouch}>
      <div>
        <span className="area-name">{area.area}</span>
        {area.isOn === "TRUE" && isHeatingOn && (
          <span
            className="area-heat"
            dangerouslySetInnerHTML={{ __html: flameLogo }}
          ></span>
        )}
      </div>
      <BatteryIcon batteryLevel={area.battery} />

      <div className="area-status">
        {area.status}
        {String.fromCharCode(176)}
      </div>
      <div className="area-info">
        {area.targetHeating && (
          <div>
            <span
              className="area-target"
              dangerouslySetInnerHTML={{
                __html: flameLogoMini + " " + area.targetHeating,
              }}
            ></span>
            {String.fromCharCode(176)}
          </div>
        )}
        <div
          className="area-humidity"
          dangerouslySetInnerHTML={{
            __html: humLogo + " " + area.humidity + "%",
          }}
        ></div>
        {area.lastupdated && (
          <div className="area-lastupdated">
            <span dangerouslySetInnerHTML={{ __html: refreshLogo }} />
            {parseDate(area.lastupdated)}
          </div>
        )}
      </div>
    </div>
  );
};

// Componente per l'icona della batteria
interface BatteryIconProps {
  batteryLevel: number;
}

const BatteryIcon: React.FC<BatteryIconProps> = ({ batteryLevel }) => {
  const createBatteryIconNode = (perc: number) => {
    const batW = 35;
    const batH = (batW * 4) / 7;
    const marginH = 3;
    const marginV = 3;
    const testaW = 6;
    const testaH = batH / 2;
    const areaW = marginH * 2 + batW + testaW;
    const areaH = marginV * 2 + batH;
    const viewBox = "0 0 " + areaW + " " + areaH;
    const color = "#fff";
    const fontSize = batH / 2;
    const strokeW = 2;

    return (
      <svg
        width={areaW}
        height={areaH}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x={marginH}
          y={marginV}
          width={batW}
          height={batH}
          rx="3"
          ry="3"
          stroke={color}
          fill="none"
          strokeWidth={strokeW}
        ></rect>
        <rect
          x={batW + marginH}
          y={marginV + (batH - testaH) / 2}
          width={testaW}
          height={testaH}
          rx="1"
          ry="1"
          stroke={color}
          fill="none"
          strokeWidth={strokeW}
        ></rect>
        <text
          x={marginH + batW / 2}
          y={areaH / 2}
          fontFamily="Arial"
          textAnchor="middle"
          dominantBaseline="middle"
          fill={color}
          fontSize={fontSize + "px"}
        >
          {perc}
        </text>
      </svg>
    );
  };

  if (!Number.isNaN(batteryLevel))
    return (
      <span className="battery-icon">
        {createBatteryIconNode(batteryLevel)}
      </span>
    );
  return <></>;
};

export default AreaElement;
