import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import HeatDashboard from "./Heat/HeatDashboard";
import DataManager from "../API/DataManager";
import { AreaModel } from "../components/Area/Area";
import { useDispatch } from "react-redux";
import { setDate, setTime, setIsLoading } from "../store/store";

const App: React.FC = () => {
  const [areas, setAreas] = useState<Array<AreaModel>>([]);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();

  const isFetchingDataRef = useRef(false);

  const fetchData = async () => {
    isFetchingDataRef.current = true;
    dispatch(setIsLoading(true));

    try {
      const result = await DataManager.getOldAppData();

      dispatch(setDate(result.date));
      dispatch(setTime(result.time));

      const transformedAreas = result.areas.map((area: AreaModel) => {
        return {
          ...area,
          status: parseFloat(area.status.toString().replace(",", ".")),
          humidity: parseFloat(area.humidity.toString().replace(",", ".")),
          battery: parseFloat(area.battery.toString().replace(",", ".")),
          heating: parseFloat(area.heating.toString().replace(",", ".")),
        };
      });

      setAreas(transformedAreas);
    } catch (err: any) {
      setError(err.message);
    } finally {
      dispatch(setIsLoading(false));
      isFetchingDataRef.current = false;
    }
  };

  const changeAreaCallback = (areas: Array<AreaModel>) => {
    setAreas(areas);
  };

  useEffect(() => {
    if (!isFetchingDataRef.current) fetchData();
  }, []);

  if (error) return <div>Errore home: {error}</div>;

  return (
    <div className="App">
      <Header />
      <HeatDashboard areaData={areas} areaDataChanged={changeAreaCallback} />
    </div>
  );
};

export default App;
