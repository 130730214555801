import React from "react";
import "./LoadingSpinner.css";

interface LoadingSpinnerProps {
  size?: number;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 40 }) => {
  return (
    <span className="spinner-container">
      <div
        className="loading-spinner"
        style={{ width: size, height: size }}
      ></div>
    </span>
  );
};

export default LoadingSpinner;
