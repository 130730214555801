import React, { useState } from "react";
import "./Sensor.css";
import Switch from "react-switch";

interface SensorProps {
  key: string;
  device: any; // Puoi usare un tipo più specifico se conosci la struttura
}

interface SensorData {
  value: number | boolean | string | undefined;
  lastUpdate: Date | undefined;
}

const Sensor: React.FC<SensorProps> = ({ device }) => {
  const name = device.name;
  let temperature: SensorData = { value: undefined, lastUpdate: undefined };
  let pressure: SensorData = { value: undefined, lastUpdate: undefined };
  let humidity: SensorData = { value: undefined, lastUpdate: undefined };
  let battery: SensorData = { value: undefined, lastUpdate: undefined };
  let isOn: SensorData = { value: undefined, lastUpdate: undefined };
  let reachable: SensorData = { value: undefined, lastUpdate: undefined };
  let isActive: SensorData = { value: undefined, lastUpdate: undefined };
  let current: SensorData = { value: undefined, lastUpdate: undefined };
  let power: SensorData = { value: undefined, lastUpdate: undefined };
  let voltage: SensorData = { value: undefined, lastUpdate: undefined };
  let consumption: SensorData = { value: undefined, lastUpdate: undefined };
  let heatsetpoint: SensorData = { value: undefined, lastUpdate: undefined };
  let locked: SensorData = { value: undefined, lastUpdate: undefined };
  let mode: SensorData = { value: undefined, lastUpdate: undefined };
  let offset: SensorData = { value: undefined, lastUpdate: undefined };
  let preset: SensorData = { value: undefined, lastUpdate: undefined };
  let windowopen: SensorData = { value: undefined, lastUpdate: undefined };

  const [changeActivationRequest, setChangeActivationRequest] = useState(
    isActive.value
  );

  const humLogo =
    '<svg class="svgIcon" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" width="12px" height="12px" viewBox="0 0 470.34 470.34"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M235.169,470.34c99.262,0,180.016-80.756,180.016-180.018c0-41.055-28.979-101.928-86.129-180.933 C287.262,51.617,244.866,4.585,244.442,4.117C242.072,1.496,238.705,0,235.17,0c-3.534,0-6.903,1.496-9.273,4.117 c-0.423,0.469-42.819,47.5-84.613,105.272c-57.152,79.004-86.13,139.878-86.13,180.933 C55.152,389.584,135.907,470.34,235.169,470.34z M161.41,124.223c29.423-40.688,59.156-75.953,73.761-92.8 c14.578,16.813,44.227,51.977,73.628,92.621c53.244,73.6,81.387,131.099,81.387,166.278c0,85.477-69.541,155.018-155.017,155.018 c-85.477,0-155.017-69.541-155.017-155.018C80.152,255.173,108.251,197.736,161.41,124.223z"></path> </g> </g></svg>';
  const refreshLogo =
    '<svg class="svgIcon" height="11px" width="11px" viewBox="0 0 383.748 383.748" version="1.1" id="refresh-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30 C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593 L2.081,34.641v113.365h113.91L62.772,95.042z"></path> <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042 c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888 c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z"></path> </g> </g></svg>';

  const toDate = (date: string): Date => {
    // i timestamp che arrivano dai sensori manca la Z, ma da documentazione sono ISO 8601, e con la Z sono a posto
    // quelli che arrivano da devices hanno la Z, ma sono già con l'ora avanti
    // quindi sistemo
    if (!date.endsWith("Z")) {
      date += "Z";
      return new Date(date);
    }

    let d = new Date(date);
    d.setHours(d.getHours() - 1);
    return d;
  };

  const updateSensorData = (
    sensor: SensorData,
    value: any,
    lastUpdated: string | undefined,
    lastUpdated2?: string | undefined
  ) => {
    const getMostRecentDate = (date1: Date, date2: Date) => {
      return date1 > date2 ? date1 : date2;
    };

    if (lastUpdated !== undefined && lastUpdated2 !== undefined) {
      const d1 = toDate(lastUpdated);
      const d2 = toDate(lastUpdated2);
      sensor.lastUpdate = getMostRecentDate(d1, d2);
    } else sensor.lastUpdate = lastUpdated ? toDate(lastUpdated) : undefined;

    sensor.value = value;
  };

  for (let i = 0; i < device.subdevices.length; i++) {
    const subdev = device.subdevices[i];

    switch (subdev.type) {
      case "ZHATemperature":
        updateSensorData(
          temperature,
          subdev.state.temperature?.value,
          subdev.state.temperature?.lastupdated,
          subdev.state.temperature?.lastupdated_fromsensor
        );
        break;

      case "ZHAHumidity":
        updateSensorData(
          humidity,
          subdev.state.humidity?.value,
          subdev.state.humidity?.lastupdated,
          subdev.state.humidity?.lastupdated_fromsensor
        );
        break;

      case "ZHAPressure":
        updateSensorData(
          pressure,
          subdev.state.pressure?.value,
          subdev.state.pressure?.lastupdated,
          subdev.state.pressure?.lastupdated_fromsensor
        );
        break;

      case "ZHAThermostat":
        updateSensorData(
          isActive,
          subdev.state.on?.value,
          subdev.state.on?.lastupdated,
          subdev.state.on?.lastupdated_fromsensor
        );
        updateSensorData(
          heatsetpoint,
          subdev.config?.heatsetpoint.value,
          subdev.config?.heatsetpoint.lastupdated
        );
        updateSensorData(
          locked,
          subdev.config?.locked.value,
          subdev.config?.locked.lastupdated
        );
        updateSensorData(
          mode,
          subdev.config?.mode.value,
          subdev.config?.mode.lastupdated
        );
        updateSensorData(
          offset,
          subdev.config?.offset.value,
          subdev.config?.offset.lastupdated
        );
        updateSensorData(
          preset,
          subdev.config?.preset.value,
          subdev.config?.preset.lastupdated
        );
        break;

      case "ZHAConsumption":
        updateSensorData(
          consumption,
          subdev.state.consumption?.value,
          subdev.state.consumption?.lastupdated,
          subdev.state.consumption?.lastupdated_fromsensor
        );
        break;

      case "ZHAPower":
        updateSensorData(
          current,
          subdev.state.current?.value,
          subdev.state.current?.lastupdated,
          subdev.state.current?.lastupdated_fromsensor
        );
        updateSensorData(
          power,
          subdev.state.power?.value,
          subdev.state.power?.lastupdated,
          subdev.state.power?.lastupdated_fromsensor
        );
        updateSensorData(
          voltage,
          subdev.state.voltage?.value,
          subdev.state.voltage?.lastupdated,
          subdev.state.voltage?.lastupdated_fromsensor
        );
        break;

      case "Smart plug":
        updateSensorData(
          isActive,
          subdev.state.on?.value,
          subdev.state.on?.lastupdated
        );
        break;
    }

    if (subdev.config) {
      if (subdev.config.battery) {
        updateSensorData(
          battery,
          subdev.config.battery?.value,
          subdev.config.battery?.lastupdated
        );
      }

      if (subdev.config.on) {
        updateSensorData(
          isOn,
          subdev.config.on?.value,
          subdev.config.on?.lastupdated
        );
      }

      if (subdev.config.reachable) {
        updateSensorData(
          reachable,
          subdev.config.reachable?.value,
          subdev.config.reachable?.lastupdated
        );
      }
    }
  }

  const getPrintableTime = (datetime: Date): string => {
    const now = new Date();

    const diffTime = Math.abs(datetime.getTime() - now.getTime());

    if (Math.floor(diffTime / 1000 / 60) < 1) {
      return "now";
    } else if (Math.floor(diffTime / 1000 / 60) < 59) {
      return Math.floor(diffTime / 1000 / 60) + " min fa";
    } else if (Math.floor(diffTime / 1000 / 60 / 60) < 3) {
      return Math.floor(diffTime / 1000 / 60 / 60) + " ore fa";
    } else if (Math.floor(diffTime / 1000 / 60 / 60) < 24) {
      let hours = String(datetime.getHours()).padStart(2, "0");
      let minutes = String(datetime.getMinutes()).padStart(2, "0");
      let day = String(datetime.getDate()).padStart(2, "0");
      let monthNames = [
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "set",
        "ott",
        "nov",
        "dic",
      ];
      let month = monthNames[datetime.getMonth()];
      return `${hours}:${minutes}, ${day} ${month}`;
    } else {
      return datetime.toLocaleDateString();
    }
  };

  const lastUpdateNode = (lastupd: Date) => {
    let str = getPrintableTime(lastupd);
    return (
      <div className="last-update">
        {str + " "}
        <span dangerouslySetInnerHTML={{ __html: refreshLogo }} />
      </div>
    );
  };

  const humidityNode = (val: number) => {
    return (
      <span className="inline">
        <div dangerouslySetInnerHTML={{ __html: humLogo }} />
        {val + "%"}
      </span>
    );
  };

  const onActivationSwitchPress = (checked: boolean) => {
    console.log(checked, device);

    for (let i = 0; i < device.subdevices.length; i++) {
      const subdev = device.subdevices[i];
      if (subdev.type == "Smart plug") {
        handlePostRequest(
          "lights/" + subdev.uniqueid + "/state",
          JSON.stringify({ on: checked, transitiontime: 9 })
        );
      }
    }
    setChangeActivationRequest(checked);
  };

  const handlePostRequest = async (url: string, body: string) => {
    const u = "https://home.web22.it/___sensorsActions.php"; // Sostituisci con il tuo endpoint API

    const payload = {
      u: url,
      b: body,
    };

    try {
      const response = await fetch(u, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        console.log("Successo: " + JSON.stringify(jsonResponse));
      } else {
        const errorResponse = await response.json();
        console.error("Errore: " + JSON.stringify(errorResponse));
      }
    } catch (error: any) {
      console.error("Si è verificato un errore: " + error.message);
    }
  };
  return (
    <div className="sensor">
      <h3>{name}</h3>

      {temperature.value !== undefined && (
        <div className="sensor-row">
          <p>
            {(temperature.value as number) / 100}
            {String.fromCharCode(176)}
          </p>
          {temperature.lastUpdate && lastUpdateNode(temperature.lastUpdate)}
        </div>
      )}
      {humidity.value !== undefined && (
        <div className="sensor-row">
          {humidityNode((humidity.value as number) / 100)}
          {humidity.lastUpdate && lastUpdateNode(humidity.lastUpdate)}
        </div>
      )}

      {pressure.value !== undefined && (
        <div className="sensor-row">
          <p>pressure: {pressure.value} hPa</p>
          {pressure.lastUpdate && lastUpdateNode(pressure.lastUpdate)}
        </div>
      )}

      {battery.value !== undefined && (
        <div className="sensor-row">
          <p>{battery.value}% battery</p>
          {battery.lastUpdate && lastUpdateNode(battery.lastUpdate)}
        </div>
      )}

      {current.value !== undefined && (
        <div className="sensor-row">
          <p>current: {current.value} A</p>
          {current.lastUpdate && lastUpdateNode(current.lastUpdate)}
        </div>
      )}

      {power.value !== undefined && (
        <div className="sensor-row">
          <p>power: {power.value} W</p>
          {power.lastUpdate && lastUpdateNode(power.lastUpdate)}
        </div>
      )}

      {voltage.value !== undefined && (
        <div className="sensor-row">
          <p>voltage: {voltage.value} V</p>
          {voltage.lastUpdate && lastUpdateNode(voltage.lastUpdate)}
        </div>
      )}

      {consumption.value !== undefined && (
        <div className="sensor-row">
          <p>consumption: {consumption.value} Wh</p>
          {consumption.lastUpdate && lastUpdateNode(consumption.lastUpdate)}
        </div>
      )}

      {heatsetpoint.value !== undefined && (
        <div className="sensor-row">
          <p>
            Heatpoint: {(heatsetpoint.value as number) / 100}
            {String.fromCharCode(176)}
          </p>
          {heatsetpoint.lastUpdate && lastUpdateNode(heatsetpoint.lastUpdate)}
        </div>
      )}

      {locked.value !== undefined && (
        <div className="sensor-row">
          <p>{locked.value ? "Locked" : "Unlocked"}</p>
          {locked.lastUpdate && lastUpdateNode(locked.lastUpdate)}
        </div>
      )}

      {mode.value !== undefined && (
        <div className="sensor-row">
          <p>mode: {mode.value}</p>
          {mode.lastUpdate && lastUpdateNode(mode.lastUpdate)}
        </div>
      )}

      {offset.value !== undefined && (
        <div className="sensor-row">
          <p>offset: {offset.value}</p>
          {offset.lastUpdate && lastUpdateNode(offset.lastUpdate)}
        </div>
      )}

      {preset.value !== undefined && (
        <div className="sensor-row">
          <p>preset: {preset.value}</p>
          {preset.lastUpdate && lastUpdateNode(preset.lastUpdate)}
        </div>
      )}

      {windowopen.value !== undefined && (
        <div className="sensor-row">
          <p>window open: {windowopen.value ? "Yes" : "No"}</p>
          {windowopen.lastUpdate && lastUpdateNode(windowopen.lastUpdate)}
        </div>
      )}

      {isOn.value !== undefined && (
        <div className="sensor-row">
          <p>{isOn.value ? "Acceso" : "Spento"}</p>
          {isOn.lastUpdate && lastUpdateNode(isOn.lastUpdate)}
        </div>
      )}

      {reachable.value !== undefined && (
        <div className="sensor-row">
          <p>{reachable.value ? "Raggiungibile" : "Non raggiungibile"}</p>
          {reachable.lastUpdate && lastUpdateNode(reachable.lastUpdate)}
        </div>
      )}

      {isActive.value !== undefined && (
        <div className="sensor-row">
          <Switch
            onChange={onActivationSwitchPress}
            checked={changeActivationRequest as boolean}
          />
          {isActive.lastUpdate && lastUpdateNode(isActive.lastUpdate)}
        </div>
      )}
    </div>
  );
};

export default Sensor;
