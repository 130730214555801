import React from "react";
import TripleSwitch from "../TripleSwitch/TripleSwitch";

const HeatTripleSwitch: React.FC = () => {
  const labels = {
    left: {
      title: "Off",
      value: "off",
      desc: "Off heat setting",
    },
    center: {
      title: "On",
      value: "On",
      desc: "Standard heat setting",
    },
    right: {
      title: "Max",
      value: "max",
      desc: "Max heat setting",
    },
  };

  const handleChange = (value: string) => {
    console.log("Selected heat setting:", value);
  };

  return <TripleSwitch labels={labels} onChange={handleChange} />;
};

export default HeatTripleSwitch;
