import React from "react";
import "./App.css";
import { IoHome } from "react-icons/io5";
import { MdWindow } from "react-icons/md";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import Home from "./screen/Home";
import AllSensors from "./screen/Sensors/AllSensors";

const App: React.FC = () => {
  return (
    <Router>
      {/* Barra di navigazione */}
      <nav style={navStyle}>
        <NavLink
          to="/"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          <IoHome size={24} />
        </NavLink>
        <NavLink
          to="/allsensors"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          <MdWindow size={24} />
        </NavLink>
      </nav>

      {/* Contenuto principale */}
      <div className="App-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/allsensors" element={<AllSensors />} />
        </Routes>
      </div>
    </Router>
  );
};

const navStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  backgroundColor: "#f0f0f0",
  padding: "25px 0",
  position: "fixed",
  bottom: 0,
  width: "100%",
  zIndex: 100,
  boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
};

const linkStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
  color: "gray",
};

const activeLinkStyle: React.CSSProperties = {
  ...linkStyle,
  color: "blue",
};

export default App;
