import React from "react";
import "./TripleSwitch.css";

interface Label {
  title: string;
  value: string;
  desc?: string;
}

interface Labels {
  left: Label;
  center: Label;
  right: Label;
}

interface TripleSwitchProps {
  labels: Labels;
  onChange: (value: string) => void;
  styles?: React.CSSProperties;
}

interface TripleSwitchState {
  switchPosition: "left" | "center" | "right";
  animation: string | null;
  showDescription: boolean[];
}

class TripleSwitch extends React.Component<
  TripleSwitchProps,
  TripleSwitchState
> {
  static defaultProps: Partial<TripleSwitchProps> = {
    labels: {
      left: {
        title: "left",
        value: "left",
      },
      center: {
        title: "center",
        value: "center",
      },
      right: {
        title: "right",
        value: "right",
      },
    },
    onChange: (value) => console.log("value:", value),
  };

  constructor(props: TripleSwitchProps) {
    super(props);
    this.state = {
      switchPosition: "left",
      animation: null,
      showDescription: [false, false, false],
    };
  }

  getSwitchAnimation = (value: string) => {
    const { switchPosition } = this.state;
    let animation = null;
    if (value === "center" && switchPosition === "left") {
      animation = "left-to-center";
    } else if (value === "right" && switchPosition === "center") {
      animation = "center-to-right";
    } else if (value === "center" && switchPosition === "right") {
      animation = "right-to-center";
    } else if (value === "left" && switchPosition === "center") {
      animation = "center-to-left";
    } else if (value === "right" && switchPosition === "left") {
      animation = "left-to-right";
    } else if (value === "left" && switchPosition === "right") {
      animation = "right-to-left";
    }
    this.props.onChange(value);
    this.setState({
      switchPosition: value as "left" | "center" | "right",
      animation,
    });
  };

  handleMouseEnter = (index: number) => {
    this.setState((prevState) => {
      const newShowDescription = [...prevState.showDescription];
      newShowDescription[index] = true;
      return { showDescription: newShowDescription };
    });
  };

  handleMouseLeave = (index: number) => {
    this.setState((prevState) => {
      const newShowDescription = [...prevState.showDescription];
      newShowDescription[index] = false;
      return { showDescription: newShowDescription };
    });
  };

  render() {
    const { labels } = this.props;
    return (
      <div
        className={`main-container ${
          this.state.switchPosition === "left" &&
          "left-container-backgroundcolor"
        } 
        ${
          this.state.switchPosition === "center" &&
          "center-container-backgroundcolor"
        } 
        ${
          this.state.switchPosition === "right" &&
          "right-container-backgroundcolor"
        } `}
      >
        <div
          className={`switch 
            ${this.state.animation} 
            ${this.state.switchPosition}-position 
            ${this.state.switchPosition === "left" && "left-backgroundcolor"} 
            ${
              this.state.switchPosition === "center" && "center-backgroundcolor"
            } 
            ${
              this.state.switchPosition === "right" && "right-backgroundcolor"
            } `}
        ></div>
        <input
          defaultChecked
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="left"
          type="radio"
          value="left"
        />
        <label
          className={`left-label ${
            this.state.switchPosition === "left" && "active-font"
          } ${this.state.switchPosition === "left" && "left-backgroundcolor"} `}
          htmlFor="left"
          onMouseEnter={() => this.handleMouseEnter(0)}
          onMouseLeave={() => this.handleMouseLeave(0)}
        >
          <h4>{labels.left.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="center"
          type="radio"
          value="center"
        />
        <label
          className={`center-label 
            ${this.state.switchPosition === "center" && "active-font"} 
            ${
              this.state.switchPosition === "center" && "center-backgroundcolor"
            } 
            `}
          htmlFor="center"
          onMouseEnter={() => this.handleMouseEnter(1)}
          onMouseLeave={() => this.handleMouseLeave(1)}
        >
          <h4>{labels.center.title}</h4>
        </label>

        <input
          onChange={(e) => this.getSwitchAnimation(e.target.value)}
          name="map-switch"
          id="right"
          type="radio"
          value="right"
        />
        <label
          className={`right-label ${
            this.state.switchPosition === "right" && "active-font"
          } ${
            this.state.switchPosition === "right" && "right-backgroundcolor"
          } `}
          htmlFor="right"
          onMouseEnter={() => this.handleMouseEnter(2)}
          onMouseLeave={() => this.handleMouseLeave(2)}
        >
          <h4>{labels.right.title}</h4>
        </label>
      </div>
    );
  }
}

export default TripleSwitch;
