import React, { useState } from "react";
import { AreaModel } from "../Area/Area";
import "./LevelControl.css";

interface LevelControlProps {
  onClose: (area: AreaModel) => void;
  area: AreaModel | null;
}

const LevelControl: React.FC<LevelControlProps> = ({ onClose, area }) => {
  const calculatePercentage = (temperatureValue: number): number => {
    return Math.min(Math.max((temperatureValue / maxValue) * 100, 0), 100);
  };

  const step = 0.5;
  const minValue = 5;
  const maxValue = 25;

  let initialTargetTemp = area?.targetHeating || area?.status || 15;
  initialTargetTemp = Math.round(initialTargetTemp / step) * step;

  const [targetTemp, setTargetTemp] = useState(initialTargetTemp);
  const [fillPercentage, setFillPercentage] = useState(
    calculatePercentage(targetTemp)
  );
  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
    updateFillPercentage(e.clientY);
  };

  const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
    if (isDragging) {
      e.preventDefault();
      updateFillPercentage(e.clientY);
    }
  };

  const handlePointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const updateFillPercentage = (clientY: number) => {
    const container = document.getElementById("level-control");
    if (container) {
      const rect = container.getBoundingClientRect();
      const newPercentage = Math.min(
        Math.max(((rect.bottom - clientY) / rect.height) * 100, 0),
        100
      );
      setFillPercentage(newPercentage);
    }
    setTargetTemp(calculateTemp(fillPercentage));
  };

  const extraFillAreaStyle: React.CSSProperties = {
    height: `${fillPercentage}%`,
    transition: isDragging ? "none" : "height 0.3s ease",
  };

  const calculateTemp = (percentage: number): number => {
    if (percentage === 0) return 0;
    if (percentage === 1) return 5;
    if (percentage === 100) return 25;

    const valueRange = maxValue - minValue;
    const value = minValue + (valueRange * (percentage - 1)) / 99;

    // Arrotonda al più vicino step
    return Math.round(value / step) * step;
  };

  const onLevelClose = () => {
    const newArea = {
      ...area,
      targetHeating: targetTemp,
    } as AreaModel;
    onClose(newArea);
  };

  return (
    <div id="overlay" onClick={onLevelClose}>
      <div className="level-control-container">
        <div>
          <div className="level-area-name">{area?.area}</div>
          <div className="actual-temp-label-level-screen">
            Temperatura attuale:
          </div>
          <span /> {/* Spaziatore */}
          <div className="actual-temp-level-screen">{area?.status} °C</div>
        </div>
        <div className="target-temp-container">
          {targetTemp > 0 ? targetTemp + " °C" : "OFF"}
        </div>
        <div
          id="level-control"
          onClick={(e) => e.stopPropagation()}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          onPointerLeave={handlePointerUp} // Per assicurare il rilascio anche fuori dal contenitore
        >
          {/* Area riempita */}
          <div id="level-fill-area" style={extraFillAreaStyle}></div>
        </div>
      </div>
    </div>
  );
};

export default LevelControl;
