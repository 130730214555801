class DataManager {
  private static baseUrl: string = "https://home.web22.it"; // Sostituisci con l'URL base del tuo backend

  public static async getSensors(): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}/___getSensors.php`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const resp = await response.json();
      if (typeof resp === "object") {
        return resp;
      }

      throw new Error("La risposta non è un JSON valido");
    } catch (error) {
      console.error("Errore nella richiesta:", error);
      throw error;
    }
  }

  public static async getOldAppData(): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}/getData.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const resp = await response.json();
      if (typeof resp === "object") {
        return resp;
      }

      throw new Error("La risposta non è un JSON valido");
    } catch (error) {
      console.error("Errore nella richiesta:", error);
      throw error;
    }
  }
}

export default DataManager;
