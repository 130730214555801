import React from "react";
import "./Header.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const Header: React.FC = () => {
  const date = useSelector((state: RootState) => state.app.date);
  const time = useSelector((state: RootState) => state.app.time);
  const isLoading = useSelector((state: RootState) => state.app.isLoading);

  return (
    <header>
      <span>
        <span className="title">Casa</span>
        <span className="spinner-container">
          {isLoading && <LoadingSpinner size={13} />}
        </span>
      </span>
      <div id="update-info">
        <div id="update-info-label">Last update:</div>
        <div id="update-info-date">{date}</div>
        <div id="update-info-time">{time}</div>
      </div>
      <div className="clearfix"></div>
    </header>
  );
};

export default Header;
